































































































































import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import {Payment, PaymentRequest} from '@/models/order/Payment';
import {formatNumber} from "@/common";
import _each from 'lodash/each';
import _find from 'lodash/find';
import moment from 'moment';
import vSelect from 'vue-select'
import {OrderService} from '@/views/order/OrderService';
import {Money} from 'v-money';
import {PaymentMethod} from "@/constants/Order";

@Component({
    components: {
        vSelect,
        Money
    }
})
export default class PaymentTimeline extends Vue{
    @Prop({required: true}) data !: Array<Payment>;
    @Prop({default: 0}) type!: number;

    private orderService = new OrderService();

    constructor() {
        super();            
    }

    moneyOption: any = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };
    
    payment: Payment = new Payment("", PaymentMethod.Cash, "");
    paymentMethods: any = [];
    title: string = '';
    popupActive: boolean = false;
    forceRender: number = 0;

    payments: Array<Payment> = new Array<Payment>();
    paidMoney: number = 0;
    remainMoney: number = 0;
    orderCode: string = "";
    // totalAmountReturn: number = 0;
    orderId: string = "";
    paymentMethodConstant = PaymentMethod;

    @Watch('payment.money', {immediate: true, deep: true})
    onMoneyChange(newValue: number){
        if (newValue > 0 && newValue > this.remainMoney) {
            this.payment.money = this.remainMoney;
            
            setTimeout(() => {
                this.forceRender += 1;
            }, 100)
        }
    }

    async mounted(){
        const result = await this.orderService.getPaymentMethods();

        if (result) {
            this.paymentMethods = result;
            this.payment.paymentMethod = 1;
        }
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }

    openOrderPayment(){
        this.payment.money = this.remainMoney;
        this.popupActive = true;
        this.title = `Xác nhận thanh toán cho đơn ${this.orderCode}`;
    }

    async save(){
        if (this.payment.money === 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng nhập tiền thanh toán!",
                color: "danger"
            });
        }

         let paymentMethod = _find(this.paymentMethods, (item: any) => {
            return item.value === this.payment.paymentMethod;
        });
        
        this.payment.orderId = this.orderId;
        this.payment.name = paymentMethod ? paymentMethod.name : '';
        this.$delete(this.payment, 'creatorFullName');
        this.$delete(this.payment, 'createTime');
        
        let data = new PaymentRequest();
        data.id = this.orderId;
        data.payments =  [this.payment];

        try {
            this.$vs.loading()
            let result = await this.orderService.paymentInsert(data);
            this.$vs.loading.close();

            if (result.code > 0) {
                let resultData = result.data;

                if (resultData) {
                    this.popupActive = false;
                    this.remainMoney -=  this.payment.money;
                    this.paidMoney +=  this.payment.money;

                    let datetimeFormat = 'DD-MM-YYYY HH:mm';

                    _each(resultData, (item: Payment) => {
                        item.createTime = moment(item.createTime).format(datetimeFormat);
                    });

                    this.payments = resultData.concat(this.payments);
                }
            }

            this.$vs.notify({
                title: '',
                text: result.message,
                color: result.code <= 0 ? 'danger' : 'success'
            });
        } catch (error) {
            this.$vs.loading.close();

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }

    @Watch('data')
    onDataChange(newData: any){
        if (newData) {
            let datetimeFormat = 'DD-MM-YYYY HH:mm';

            _each(newData.payments, (item: Payment) => {
                item.createTime = moment(item.createTime).format(datetimeFormat);

                this.payments.push(item);
            });
            
            this.orderId = newData.orderId;
            this.orderCode = newData.orderCode;
            this.paidMoney = newData.paidPrice;
            // this.totalAmountReturn = newData.totalAmountReturn;
        }
    }

    get headerTitle(){
        return this.type === 0 ? 'Thanh toán' : 'Hoàn tiền';
    }

    get contentTitle(){
        return this.type === 0 ? 'Đã thanh toán' : 'Đã hoàn tiền';
    }
}

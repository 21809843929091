import axios from 'axios';
import {Order} from '@/models/order/Order';
import {OrderGetByIdResult} from '@/models/order/OrderGetByIdResult';
import {OrderInfoGetByCustomerResult} from '@/models/order/OrderInfoGetByCustomerResult';

import {ActionResult} from '@/models/ActionResult';
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {LoadResult} from "@/models/LoadResult";
import {ReportByCustomerId} from "@/models/order/ReportByCustomerId";
import {OrderPrintResult} from "@/views/order/order-print/OrderPrintResult";
import {Status} from "@/constants/Order";
import {Payment, PaymentRequest} from '@/models/order/Payment';

export class OrderService {
    url = `${process.env.VUE_APP_ORDER_URL}api/v1/orders`;

    search(loadOptions: QueryLoadOption): Promise<LoadResult> {
        return axios.post(`${this.url}/search`, loadOptions);
    }

    searchLogistic(loadOptions: QueryLoadOption, driver: string): Promise<LoadResult> {
        return axios.post(`${this.url}/search-logistic?driverId=${driver}`, loadOptions);
    }

    countByQuery(loadOptions: QueryLoadOption): Promise<number> {
        return axios.post(`${this.url}/count-by-query`, loadOptions);
    }

    countForLogistic(loadOptions: QueryLoadOption): Promise<number> {
        return axios.post(`${this.url}/count-for-logistic`, loadOptions);
    }

    getByCustomer(loadOptions: QueryLoadOption, customerId: string): Promise<LoadResult> {
        return axios.post(`${this.url}/search/customers/${customerId}`, loadOptions);
    }

    insert(order: Order): Promise<ActionResult<string>> {
        return axios.post(`${this.url}`, order) as Promise<ActionResult<string>>;
    }

    update(order: Order): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${order.id}`, order) as Promise<ActionResult<string>>;
    }

    delete(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    getById(id: string): Promise<ActionResult<OrderGetByIdResult>> {
        return axios.get(`${this.url}/${id}`);
    }

    getInformationGetByCustomer(customerId: string, code: any): Promise<ActionResult<OrderInfoGetByCustomerResult>> {
        return axios.get(`${this.url}/${customerId}/order-information-of-customer?code=${code}`);
    }

    getAttributeResult(): Promise<FilterAttributeResult[]> {
        return axios.get(`${this.url}/attributes`);
    }

    reportByCustomer(customerId: string): Promise<ReportByCustomerId> {
        return axios.get(`${this.url}/report/${customerId}`);
    }

    async exportPdf(orderId: string) {
        let result = await axios.get(`${process.env.VUE_APP_ORDER_URL}api/v1/order-exports/pdf/${orderId}`, {responseType: 'blob'}) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download',
            `INKA CRM@Phieu xuat kho - Bien ban ban giao.pdf`
        );

        document.body.appendChild(link);
        link.click();
        return true;
    }

    getDeliveryPlans(id: string): Promise<ActionResult<Array<any>>> {
        return axios.get(`${this.url}/${id}/get-delivery-plans`);
    }

    updateDeliveryPlans(id: string, deliveryPlans: any): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/${id}/delivery-plans`, deliveryPlans) as Promise<ActionResult<any>>;
    }

    insertDeliveryPlans(id: string, deliveryPlans: any): Promise<ActionResult<any>> {
        return axios.post(`${this.url}/${id}/delivery-plans`, deliveryPlans) as Promise<ActionResult<any>>;
    }

    updateStatus(id: string, status: number, reason?: string): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/${id}/status/${status}`, {Reason: reason}) as Promise<ActionResult<any>>;
    }

    deleteDeliveryPlan(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}/delivery-plan`);
    }

    searchOrderReturn(loadOptions: QueryLoadOption): Promise<LoadResult> {
        return axios.post(`${this.url}/order-can-return/search`, loadOptions);
    }

    exchangeReturn(data: any): Promise<ActionResult<string>> {
        return axios.post(`${this.url}/exchange-return`, data) as Promise<ActionResult<string>>;
    }

    orderReturnGetById(id: string): Promise<ActionResult<OrderGetByIdResult>> {
        return axios.get(`${this.url}/${id}/return`);
    }

    urlIGreen = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/ig`;

    getInventory(date: string, inventoryCode: string, products: string): Promise<Array<any>> {
        return axios.get(`${this.urlIGreen}/get-tonkho?date=${date}&inventoryCode=${inventoryCode}&products=${products}`);
    }

    getPrintDetail(orderIds: string[]): Promise<ActionResult<OrderPrintResult>[]> {
        return axios.post(`${this.url}/print`, orderIds);
    }

    isEdit(status: Status) {
        if (status === Status.Draft || status === Status.Ordered || status === Status.TotalOrder) {
            return true;
        }

        return false;
    }

    isDelete(status: Status) {
        if (status === Status.Draft || status === Status.Ordered) {
            return true;
        }

        return false;
    }

    isReturn(status: Status) {
        if (status !== Status.Draft && status !== Status.Ordered && status !== Status.TotalOrder && status !== Status.Canceled) {
            return true;
        }

        return false;
    }

    isCancel(status: Status) {
        if (status !== Status.Delivered && status !== Status.Canceled) {
            return true;
        }

        return false;
    }

    searchForReceipt(loadOptions: QueryLoadOption, code: string, customerId: string, type: number): Promise<LoadResult> {
        return axios.post(`${this.url}/search-for-receipt`, {
            loadOptions: loadOptions,
            type: type,
            code: code,
            customerId: customerId
        });
    }

    getPaymentMethods(): Promise<any> {
        return axios.get(`${this.url}/payment-methods`);
    }

    paymentInsert(payment: PaymentRequest): Promise<ActionResult<Payment[]>> {
        return axios.post(`${this.url}/payments`, payment) as Promise<ActionResult<Payment[]>>;
    }

    async exportExcel(loadOptions: QueryLoadOption) {
        let result = (await axios.post(`${process.env.VUE_APP_ORDER_URL}api/v1/order-exports/excel`,
            loadOptions, {responseType: 'blob'})) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download',
            `INKA CRM@Danh sach don hang.xlsx`
        ); //or any other extension

        document.body.appendChild(link);
        link.click();
        return true;
    }

    deleteAttachFile(id: string): Promise<any> {
        return axios.post(`${this.url}/delete-attach-file?fileId=${id}`);
    }

    saveAttachFiles(orderId: string, fileIds: string[]): Promise<ActionResult> {
        return axios.post(`${this.url}/save-attach-files`, {
            orderId: orderId,
            fileIds: fileIds
        });
    }

    updateNoteShipment(id: string, note: string): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/${id}/note-shipment`, {Note: note}) as Promise<ActionResult<any>>;
    }

    updateStatusGoodsIssued(orderIds: string[], status: Status): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/goods-issued-update-status`, {OrderIds: orderIds, Status: status}) as Promise<ActionResult<any>>;
    }

    updateNoteBusiness(id: string, note: string): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/${id}/note-business`, {Note: note}) as Promise<ActionResult<any>>;
    }

    getListStatus(id?: string): Promise<Array<any>> {
        return axios.get(`${this.url}/status?id=${id}`);
    }

    updateDeliveryDate(id: string, fromDate: string, toDate: string): Promise<ActionResult<any>> {
        return axios.put(`${this.url}/${id}/delivery-date`, {From: fromDate, To: toDate}) as Promise<ActionResult<any>>;
    }
}

import {
    DiscountType
} from "@/constants/Order";
import webpack from "webpack";
import numberToIdentifer = webpack.Template.numberToIdentifer;

export function formatNumber(value: any) {
    if (!value) {
        return 0;
    }

    let val = (value).toFixed(value.toString().indexOf('.') > 0?2:0).replace(',', '.');

    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function genarateId() {
    return (Math.random().toString(36).substr(2, 9)).toString();
}

export function  calculateOrderDetailVatMoney(discount: number, discountType: any, price: number, quantity: number, vat?: number){
    let money = 0;

    if (discountType === DiscountType.Percent) {
        money = price * (1 - discount / 100);
    } else if (discountType === DiscountType.Money) {
        money = price - discount;
    }

    if (vat && vat > 0) {
        return  money * vat/100 * quantity;
    }
    else{
        return 0
    }
}

import {PaymentMethod} from "@/constants/Order";

export class Payment {
    id: string;
    paymentMethod: PaymentMethod;
    name: string;
    orderId: string;
    money: number;
    bankId: string;
    reference: string;
    creatorFullName: string;
    createTime: any;

    constructor(id: string, paymentMethod: PaymentMethod, name: string){
        this.id = id;
        this.paymentMethod = paymentMethod;
        this.name = name;
        this.orderId = '';
        this.money = 0;
        this.bankId = '';
        this.reference = '';
        this.creatorFullName = '';
        this.createTime = '';
    }
}

export class PaymentRequest {
    id: string;
    payments: Array<Payment>;
}

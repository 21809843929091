import {OptionOrderStatus} from "@/models/order/OrderSearchResult";

export const InstallationMethods = [
    {
        id: 0,
        name: "Thuê ngoài",
    },
    {
        id: 1,
        name: "Nội bộ",
    },
    {
        id: 2,
        name: "Khách tự lắp đặt",
    },
];

export const ShippingMethods = [
    {
        id: 0,
        name: "Thuê ngoài",
    },
    {
        id: 1,
        name: "Nội bộ",
    },
    {
        id: 2,
        name: "Khách tự vận chuyển",
    },
    // {
    //     id: 3,
    //     name: "Giao ra bến"
    // }
];

export const PaymentMethods = [
    {
        id: 0,
        name: "Chuyển khoản",
    },
    {
        id: 1,
        name: "Tiền mặt",
    },
    {
        id: 2,
        name: "Thanh toán sau",
    },
    {
        id: 3,
        name: "Giao hàng nhận tiền",
    }
];

export enum Status {
    /// <summary>
    /// Nháp.
    /// </summary>
    Draft,

    /// <summary>
    /// Đặt hàng.
    /// </summary>
    Ordered,

    /// <summary>
    /// Đã xuất kho.
    /// </summary>
    GoodsIssued,

    /// <summary>
    /// Đang giao.
    /// </summary>
    Delivering,

    /// <summary>
    /// Đã giao.
    /// </summary>
    Delivered,

    /// <summary>
    /// Chờ đổi trả hàng.
    /// </summary>
    WaitingForReturnAndExchange,

    /// <summary>
    /// Không giao được.
    /// </summary>
    GoodsNotDelivered,

    /// <summary>
    /// Đã hủy.
    /// </summary>
    Canceled,

    /// <summary>
    /// Đơn tổng.
    /// </summary>
    TotalOrder,

    NotCompleted
}

export enum DiscountType {
    Percent,
    Money
}

export enum PaymentMethod {
    Transfer,
    Cash,
    PayLater,
    COD
}

export class PaymentMethodOptions {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case PaymentMethod.COD:
                this.name = 'Giao hàng nhận tiền';
                return;
            case PaymentMethod.Cash:
                this.name = 'Tiền mặt';
                return;
            case PaymentMethod.Transfer:
                this.name = 'Chuyển khoản';
                return;
            case PaymentMethod.PayLater:
                this.name = 'Trả sau'
        }
    }

}

export enum TypeView {
    List,
    Thumbnail
}

export enum ShippingMethod {
    Outsource,
    Internal,
    Customer,
    DeliveryToStation
}

export enum InstallationMethod {
    Outsource,
    Internal,
    SelfInstallation
}

export enum FormType {
    Normal = 0,
    Return = 1
}

export enum OrderType {
    Clone,
    Return,
    Exchange,
    OrderContract
}

export enum ExportType {
    All,
    CurrentPage,
    Selected,
    CurrentSearch
}

export enum PrintType {
    All,
    Other,
}

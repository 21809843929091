import axios from 'axios';
import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {LoadResult} from "@/models/LoadResult";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {AttributeType, DataType, LogicType, OperatorType} from "@/constants/Attribute";
import {ActionResult} from "@/models/ActionResult";
import {ReturnGetByIdResult} from "@/models/return/ReturnGetByIdResult";
import {OrderGetByIdResult} from "@/models/order/OrderGetByIdResult";
import {ReturnPrintResult} from "@/views/order/return-print/ReturnPrintResult";

export class ReturnService {
    url = `${process.env.VUE_APP_ORDER_URL}api/v1/returns`;

    search(loadOptions: QueryLoadOption): Promise<LoadResult> {
        return axios.post(`${this.url}/search`, loadOptions);
    }

    getById(id: string): Promise<ActionResult<ReturnGetByIdResult>> {
        return axios.get(`${this.url}/${id}`);
    }

    getOrderExchangeById(id: string): Promise<ActionResult<OrderGetByIdResult>> {
        return axios.get(`${this.url}/${id}/order-exchange`);
    }

    getPrintDetail(returnIds: string[]): Promise<ActionResult<ReturnPrintResult>[]> {
        return axios.post(`${this.url}/print`, returnIds);
    }

    async exportExcel(loadOptions: QueryLoadOption) {
        let result = (await axios.post(`${process.env.VUE_APP_ORDER_URL}api/v1/return-exports/excel`,
            loadOptions, {responseType: 'blob'})) as any;

        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `INKA CRM@Danh sach tra hang.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        return true;
    }

    getAttributeOptions() {
        let statusOptions = [
            {
                id: 1,
                name: "Đã trả",
                data: null
            },
            {
                id: 0,
                name: "Đã hủy",
                data: null
            }
        ];

        return [
            new FilterAttributeResult("OrderDate", "Ngày bán", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "", LogicType.And, DataType.DateTime),
            new FilterAttributeResult("CreateTime", "Ngày tạo", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "", LogicType.And, DataType.DateTime),
            new FilterAttributeResult("CreatorId", "Người tạo", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("CustomerId", "Khách hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("Status", "Trạng thái", "", "",
                true, AttributeType.CheckList, [], statusOptions, OperatorType.Equal, "", LogicType.And, DataType.Byte),
        ]
    }
}

import {CustomerAddress} from '@/models/customer/CustomerAddress';

export class OrderCustomer {
    id: string;
    orderId: string;
    customerId: string;
    customerCode: string;
    name: string;

    /**
     * Địa chỉ chính của khách hàng.
     */
    address: string;
    phoneNumber: string;
    taxCode: string;
    type: number;

    /**
     * Địa chỉ giao hàng
     */
    customerAddress: CustomerAddress;

    idNumber: number;
    totalDebitBalance?: number;
    totalOwned?: number;
    limit?: number;
    status: number;
    erpCode?: string;
    managerId: string;
    managerFullName: string;
    salesChannelId: string;
    salesChannelName: string;

    constructor() {
        this.customerAddress = new CustomerAddress();
    }
}
